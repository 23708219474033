var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Chi tiết hóa đơn: " + _vm.responseData.code,
            visible: _vm.dialogVisible,
            width: "1200px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    background: "#67c23a",
                    "border-top-left-radius": "5px",
                    "border-bottom-left-radius": "5px",
                  },
                  attrs: { span: 8 },
                },
                [
                  _vm._v("\n        Tổng thu:\n        "),
                  _c("span", [
                    _vm.responseDataInList.length == 0
                      ? _c("span", [_vm._v("0")])
                      : _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  this.loda.sumBy(
                                    _vm.responseDataInList,
                                    (x) => x.money
                                  )
                                )
                              )
                          ),
                        ]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticStyle: { background: "#409eff" }, attrs: { span: 8 } },
                [
                  _vm._v("\n        Đã thu:\n        "),
                  _c("span", [
                    _vm.responseDataInList.length == 0
                      ? _c("span", [_vm._v("0")])
                      : _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  this.loda.sumBy(
                                    _vm.responseDataInList,
                                    (x) => x.paid
                                  )
                                )
                              )
                          ),
                        ]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    background: "#f56c6c",
                    "border-top-right-radius": "5px",
                    "border-bottom-right-radius": "5px",
                  },
                  attrs: { span: 8 },
                },
                [
                  _vm._v("\n        Thu thiếu:\n        "),
                  _c("span", [
                    _vm.responseDataInList.length == 0
                      ? _c("span", [_vm._v("0")])
                      : _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  this.loda.sumBy(
                                    _vm.responseDataInList,
                                    (x) => x.money - x.paid
                                  )
                                )
                              )
                          ),
                        ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm.responseDataInList.length > 0
            ? _c(
                "el-table",
                {
                  ref: "multipleTable",
                  attrs: {
                    "empty-text": _vm.textTable,
                    "highlight-current-row": "",
                    data: _vm.responseDataInList,
                    "cell-style": _vm.tableRowStyle,
                    "header-cell-style": _vm.tableHeaderColor,
                    "max-height": _vm.$tableMaxHeight,
                    border: "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      fixed: "",
                      label: "STT",
                      width: "50",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Tên khoản",
                      prop: "fnPackage.name",
                      "min-width": "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Kiểu đóng", align: "center", width: "90" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.fnPackage.type == "single"
                                ? _c("span", [_vm._v("Một lần")])
                                : scope.row.fnPackage.type == "multiple"
                                ? _c("span", [_vm._v("Nhiều lần")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3517228274
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Loại", align: "center", width: "90" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.fnPackage.category == "in"
                                ? _c("span", [_vm._v("Thu")])
                                : scope.row.fnPackage.category == "out"
                                ? _c("span", [_vm._v("Chi")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1696659873
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Đơn vị",
                      prop: "fnPackage.unit",
                      align: "center",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Khóa", align: "center", width: "90" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.locked
                                ? _c("span", [_vm._v("Đã khóa")])
                                : _c("span", [_vm._v("Chưa khóa")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4261259039
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Thành tiền",
                      align: "right",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.money == 0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          scope.row.money
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1712701132
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Đã thu", align: "right", width: "120" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.paid == 0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(scope.row.paid)
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1607917804
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Thiếu", align: "right", width: "120" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.money - scope.row.paid == 0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          scope.row.money - scope.row.paid
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4162174413
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Tác vụ",
                      fixed: "right",
                      width: "100",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "success", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.orderKidsHistoryDetailMethod(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("button.view")))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1352430657
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("br"),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    background: "#67c23a",
                    "border-top-left-radius": "5px",
                    "border-bottom-left-radius": "5px",
                  },
                  attrs: { span: 8 },
                },
                [
                  _vm._v("\n        Tổng chi:\n        "),
                  _c("span", [
                    _vm.responseDataOutList.length == 0
                      ? _c("span", [_vm._v("0")])
                      : _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  this.loda.sumBy(
                                    _vm.responseDataOutList,
                                    (x) => x.money
                                  )
                                )
                              )
                          ),
                        ]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticStyle: { background: "#409eff" }, attrs: { span: 8 } },
                [
                  _vm._v("\n        Đã chi:\n        "),
                  _c("span", [
                    _vm.responseDataOutList.length == 0
                      ? _c("span", [_vm._v("0")])
                      : _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  this.loda.sumBy(
                                    _vm.responseDataOutList,
                                    (x) => x.paid
                                  )
                                )
                              )
                          ),
                        ]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    background: "#f56c6c",
                    "border-top-right-radius": "5px",
                    "border-bottom-right-radius": "5px",
                  },
                  attrs: { span: 8 },
                },
                [
                  _vm._v("\n        Chi thiếu:\n        "),
                  _c("span", [
                    _vm.responseDataOutList.length == 0
                      ? _c("span", [_vm._v("0")])
                      : _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("formatCurrencyNew")(
                                  this.loda.sumBy(
                                    _vm.responseDataOutList,
                                    (x) => x.money - x.paid
                                  )
                                )
                              )
                          ),
                        ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm.responseDataOutList.length > 0
            ? _c(
                "el-table",
                {
                  ref: "multipleTable",
                  attrs: {
                    "empty-text": _vm.textTable,
                    "highlight-current-row": "",
                    data: _vm.responseDataOutList,
                    "cell-style": _vm.tableRowStyle,
                    "header-cell-style": _vm.tableHeaderColor,
                    "max-height": _vm.$tableMaxHeight,
                    border: "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      fixed: "",
                      label: "STT",
                      width: "50",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Tên khoản",
                      prop: "fnPackage.name",
                      "min-width": "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Kiểu đóng", align: "center", width: "90" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.fnPackage.type == "single"
                                ? _c("span", [_vm._v("Một lần")])
                                : scope.row.fnPackage.type == "multiple"
                                ? _c("span", [_vm._v("Nhiều lần")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3517228274
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Loại", align: "center", width: "90" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.fnPackage.category == "in"
                                ? _c("span", [_vm._v("Thu")])
                                : scope.row.fnPackage.category == "out"
                                ? _c("span", [_vm._v("Chi")])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1696659873
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Đơn vị",
                      prop: "fnPackage.unit",
                      align: "center",
                      width: "100",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Khóa", align: "center", width: "90" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.locked
                                ? _c("span", [_vm._v("Đã khóa")])
                                : _c("span", [_vm._v("Chưa khóa")]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4261259039
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Thành tiền",
                      align: "right",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.money == 0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          scope.row.money
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1712701132
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Đã chi", align: "right", width: "120" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.paid == 0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(scope.row.paid)
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1607917804
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "Thiếu", align: "right", width: "120" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.money - scope.row.paid == 0
                                ? _c("span", [_vm._v("0")])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrency")(
                                          scope.row.money - scope.row.paid
                                        )
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4162174413
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "Tác vụ",
                      fixed: "right",
                      width: "100",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "success", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.orderKidsHistoryDetailMethod(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("button.view")))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1352430657
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("br"),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    background: "#67c23a",
                    "border-top-left-radius": "5px",
                    "border-bottom-left-radius": "5px",
                  },
                  attrs: { span: 16 },
                },
                [
                  _vm._v(
                    "\n        Còn lại phải thu phụ huynh | Thu thiếu - Chi thiếu\n      "
                  ),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    background: "#f56c6c",
                    "border-top-right-radius": "5px",
                    "border-bottom-right-radius": "5px",
                  },
                  attrs: { span: 8 },
                },
                [
                  _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("formatCurrencyNew")(
                            this.loda.sumBy(
                              _vm.responseDataInList,
                              (x) => x.money - x.paid
                            ) -
                              this.loda.sumBy(
                                _vm.responseDataOutList,
                                (x) => x.money - x.paid
                              )
                          )
                        )
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c("br"),
          _c(
            "div",
            [
              _vm.showDataCustom
                ? _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 2,
                      placeholder: "Nhập ghi chú hóa đơn",
                    },
                    model: {
                      value: _vm.responseData.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.responseData, "description", $$v)
                      },
                      expression: "responseData.description",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.showDataCustom
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "medium",
                        loading: _vm.loadingButton,
                        mini: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm()
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _c("span", [_vm._v("Lưu mô tả")]),
                    ]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("KidsPackagePaymentDetailDialog", {
        ref: "KidsPackagePaymentDetailDialog",
        attrs: { dialogVisible: _vm.showOrderHistoryDetailDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closesOrderHistoryDetailDialog()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }