var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Thống kê thu, chi học phí",
            visible: _vm.dialogVisible,
            width: "900px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "margin-bottom": "15px",
                position: "relative",
                "z-index": "1",
              },
            },
            [
              _vm._v("\n      Chọn thời gian\n      "),
              _c(
                "el-select",
                {
                  staticStyle: {
                    width: "120px",
                    "margin-left": "50px",
                    "margin-right": "5px",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.changeMonthMethod()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.startMonth,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "startMonth", $$v)
                    },
                    expression: "dataSearch.startMonth",
                  },
                },
                _vm._l(_vm.startMonthList, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.value, value: item.key },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticStyle: { width: "120px", "margin-right": "5px" },
                  on: {
                    change: function ($event) {
                      return _vm.changeMonthMethod()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.endMonth,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "endMonth", $$v)
                    },
                    expression: "dataSearch.endMonth",
                  },
                },
                _vm._l(_vm.endMonthList, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { label: item.value, value: item.key },
                  })
                }),
                1
              ),
              _c("el-date-picker", {
                staticClass: "input-common",
                staticStyle: { width: "100px" },
                attrs: {
                  clearable: false,
                  type: "year",
                  "value-format": "yyyy-MM-dd",
                  format: "yyyy",
                },
                on: {
                  change: function ($event) {
                    return _vm.searchByProperties()
                  },
                },
                model: {
                  value: _vm.dataSearch.year,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "year", $$v)
                  },
                  expression: "dataSearch.year",
                },
              }),
              _c("br"),
              _c("br"),
              _vm.showSpinner
                ? _c("spinner-custom")
                : _c("span", [
                    _c("div", { staticStyle: { margin: "0 auto" } }, [
                      _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { padding: "10px 10px" },
                                  attrs: { span: 4 },
                                },
                                [_c("div", [_vm._v("Khoản thu")])]
                              ),
                              _c(
                                "el-col",
                                {
                                  staticClass: "row-header row1-background",
                                  attrs: { span: 6 },
                                },
                                [_c("div", [_vm._v("Tổng phải thu")])]
                              ),
                              _c(
                                "el-col",
                                {
                                  staticClass: "row-header row1-background",
                                  attrs: { span: 6 },
                                },
                                [_c("div", [_vm._v("Tổng đã thu")])]
                              ),
                              _c(
                                "el-col",
                                {
                                  staticClass: "row-header row1-background",
                                  attrs: { span: 6 },
                                },
                                [_c("div", [_vm._v("Còn lại phải thu")])]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "row11 align-row",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("formatCurrencyNew")(
                                          _vm.responseData.moneyTotalIn
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                { staticClass: "row11", attrs: { span: 6 } },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("formatCurrencyNew")(
                                            _vm.responseData.moneyTotalPaidIn
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                { staticClass: "row11", attrs: { span: 6 } },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("formatCurrencyNew")(
                                            _vm.responseData.moneyTotalRemainIn
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { padding: "10px 10px" },
                                  attrs: { span: 4 },
                                },
                                [_c("div", [_vm._v("Khoản chi")])]
                              ),
                              _c(
                                "el-col",
                                {
                                  staticClass: "row-header row2-background",
                                  attrs: { span: 6 },
                                },
                                [_c("div", [_vm._v("Tổng phải chi")])]
                              ),
                              _c(
                                "el-col",
                                {
                                  staticClass: "row-header row2-background",
                                  attrs: { span: 6 },
                                },
                                [_c("div", [_vm._v("Tổng đã chi")])]
                              ),
                              _c(
                                "el-col",
                                {
                                  staticClass: "row-header row2-background",
                                  attrs: { span: 6 },
                                },
                                [_c("div", [_vm._v("Còn lại phải chi")])]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "row11 align-row",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("formatCurrencyNew")(
                                            _vm.responseData.moneyTotalOut
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                { staticClass: "row11", attrs: { span: 6 } },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("formatCurrencyNew")(
                                            _vm.responseData.moneyTotalPaidOut
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                { staticClass: "row11", attrs: { span: 6 } },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("formatCurrencyNew")(
                                            _vm.responseData.moneyTotalRemainOut
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { padding: "10px 10px" },
                                  attrs: { span: 4 },
                                },
                                [_c("div", [_vm._v("Tổng hợp")])]
                              ),
                              _c(
                                "el-col",
                                {
                                  staticClass: "row-header row3-background",
                                  attrs: { span: 6 },
                                },
                                [_c("div", [_vm._v("Tổng thu - Tổng chi")])]
                              ),
                              _c(
                                "el-col",
                                {
                                  staticClass: "row-header row3-background",
                                  attrs: { span: 6 },
                                },
                                [_c("div", [_vm._v("Đã thu - Đã chi")])]
                              ),
                              _c(
                                "el-col",
                                {
                                  staticClass: "row-header row3-background",
                                  attrs: { span: 6 },
                                },
                                [_c("div", [_vm._v("Còn lại phải thu")])]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "row11 align-row",
                                  attrs: { span: 6 },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("formatCurrencyNew")(
                                            _vm.responseData.moneyTotalInOut
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                { staticClass: "row11", attrs: { span: 6 } },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("formatCurrencyNew")(
                                            _vm.responseData.moneyTotalInOutPaid
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "el-col",
                                { staticClass: "row11", attrs: { span: 6 } },
                                [
                                  _c("div", [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm._f("formatCurrencyNew")(
                                            _vm.responseData
                                              .moneyTotalInOutRemain
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { padding: "10px 10px" },
                                  attrs: { span: 4 },
                                },
                                [_c("div", [_vm._v("Thống kê")])]
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 9 } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "primary",
                                        disabled:
                                          _vm.responseData.kidsNumber == 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.centerDialogVisible = true
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Số học sinh chưa hoàn thành hóa đơn:\n                  " +
                                          _vm._s(_vm.responseData.kidsNumber) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 9 } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "primary",
                                        disabled:
                                          _vm.responseData.orderNumber == 0,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showOrderListMethod()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Số hóa đơn chưa hoàn thành: " +
                                          _vm._s(_vm.responseData.orderNumber) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "div",
                        [
                          _c(
                            "el-row",
                            { attrs: { gutter: 20 } },
                            [
                              _c(
                                "el-col",
                                {
                                  staticStyle: { padding: "10px 10px" },
                                  attrs: { span: 4 },
                                },
                                [_vm._v("Xuất dữ liệu")]
                              ),
                              _c(
                                "div",
                                [
                                  _vm._v(
                                    "\n                1. Xuất danh sách các học sinh có hóa đơn chưa hoàn thành:\n                "
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        "text-decoration": "underline",
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.exportFileMethod("exOrder")
                                        },
                                      },
                                    },
                                    [_vm._v("Xuất file")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "align-row" },
                                [
                                  _vm._v(
                                    "\n                2. Xuất danh sách các học sinh chưa hoàn thành các khoản thu:\n                "
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        "text-decoration": "underline",
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.exportFileMethod("exIn")
                                        },
                                      },
                                    },
                                    [_vm._v("Xuất file")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "align-row" },
                                [
                                  _vm._v(
                                    "\n                3. Xuất danh sách các học sinh chưa hoàn thành các khoản chi:\n                "
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        "text-decoration": "underline",
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.exportFileMethod("exOut")
                                        },
                                      },
                                    },
                                    [_vm._v("Xuất file")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "align-row" },
                                [
                                  _vm._v(
                                    "\n                4. Xuất danh sách thu chi tổng hợp của các học sinh chưa hoàn\n                thành:\n                "
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        "text-decoration": "underline",
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.exportFileMethod("exInOut")
                                        },
                                      },
                                    },
                                    [_vm._v("Xuất file")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "align-row" },
                                [
                                  _vm._v(
                                    "\n                5. Xuất danh sách thu chi tổng hợp của các học sinh đã hoàn\n                thành:\n                "
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        "text-decoration": "underline",
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.exportFileMethod(
                                            "exInOutTrue"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Xuất file")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "align-row" },
                                [
                                  _vm._v(
                                    "\n                6. Xuất danh sách thu chi tổng hợp của các học sinh:\n                "
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        "text-decoration": "underline",
                                      },
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.exportFileMethod(
                                            "exInOutTotal"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Xuất file")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Danh sách học sinh chưa hoàn thành khoản thu",
            visible: _vm.centerDialogVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.centerDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                "empty-text": _vm.textTable,
                "highlight-current-row": "",
                data: _vm.responseData.kidsNumberList,
                "header-cell-style": _vm.$funcCommon.tableHeaderColorFunction,
                "max-height": _vm.$tableMaxHeightSmall,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  type: "index",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Học sinh", prop: "fullName" },
              }),
              _c("el-table-column", {
                attrs: { label: "Lớp", prop: "maClass.className" },
              }),
              _c("el-table-column", {
                attrs: { label: "Ngày sinh", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDate")(scope.row.birthDay))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      _vm.centerDialogVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ExportExcelKidPackage", {
        ref: "ExportExcelKidPackage",
        attrs: { dialogVisible: _vm.showExcelDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseExcelMethod()
          },
        },
      }),
      _c("ExportExcelKidPackageOut", {
        ref: "ExportExcelKidPackageOut",
        attrs: { dialogVisible: _vm.showExcelOutDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseExceOutMethod()
          },
        },
      }),
      _c("ExportExcelKidPackageInAndOut", {
        ref: "ExportExcelKidPackageInAndOut",
        attrs: { dialogVisible: _vm.showExcelInOutDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseExceInOutMethod()
          },
        },
      }),
      _c("ExportExcelKidPackageOrderKids", {
        ref: "ExportExcelKidPackageOrderKids",
        attrs: { dialogVisible: _vm.showExcelOrderDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogCloseExceOderMethod()
          },
        },
      }),
      _c("OrderKidsListDialog", {
        ref: "OrderKidsListDialog",
        attrs: { dialogVisible: _vm.showOrderKidsListDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.dialogOrderKidsListDialog()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }