var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", [
        _c("div", { staticStyle: { "margin-top": "5px" } }, [
          _c(
            "div",
            {
              staticStyle: {
                "margin-bottom": "20px",
                position: "relative",
                "z-index": "1",
                display: "inline-block",
              },
            },
            [
              _c("el-date-picker", {
                staticClass: "input-common",
                staticStyle: { width: "115px" },
                attrs: {
                  clearable: false,
                  type: "month",
                  "value-format": "yyyy-MM-dd",
                  format: "MM-yyyy",
                },
                on: {
                  change: function ($event) {
                    return _vm.searchOrderKidsByProperties()
                  },
                },
                model: {
                  value: _vm.dataSearch.date,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "date", $$v)
                  },
                  expression: "dataSearch.date",
                },
              }),
              _c(
                "el-select",
                {
                  staticClass: "input-common",
                  staticStyle: { width: "110px" },
                  attrs: { placeholder: "Trạng thái" },
                  on: {
                    change: function ($event) {
                      return _vm.searchOrderKidsByProperties()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "status", $$v)
                    },
                    expression: "dataSearch.status",
                  },
                },
                _vm._l(_vm.kidStatusList, function (item) {
                  return _c("el-option", {
                    key: item.key,
                    attrs: { value: item.key, label: item.value },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticClass: "input-common input-grade",
                  staticStyle: { width: "120px" },
                  attrs: { placeholder: "Chọn khối" },
                  on: {
                    change: function ($event) {
                      return _vm.changeGradeSearch()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.idGrade,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "idGrade", $$v)
                    },
                    expression: "dataSearch.idGrade",
                  },
                },
                _vm._l(_vm.gradeOfSchoolList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.gradeName },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticClass: "input-common input-class",
                  staticStyle: { width: "120px" },
                  attrs: { placeholder: "Chọn lớp" },
                  on: {
                    change: function ($event) {
                      return _vm.searchOrderKidsByProperties()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.idClass,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "idClass", $$v)
                    },
                    expression: "dataSearch.idClass",
                  },
                },
                _vm._l(_vm.classOfGradeList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { value: item.id, label: item.className },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  staticClass: "input-common input-class",
                  staticStyle: { width: "145px" },
                  attrs: { clearable: "", placeholder: "Chọn trạng thái" },
                  on: {
                    change: function ($event) {
                      return _vm.filterChangeStatusMethod()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.statusOrder,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "statusOrder", $$v)
                    },
                    expression: "dataSearch.statusOrder",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { value: "orderYet", label: "Chưa có hóa đơn" },
                  }),
                  _c("el-option", {
                    attrs: { value: "orderNo", label: "Chưa có khoản" },
                  }),
                  _c("el-option", {
                    attrs: { value: "completeNo", label: "Chưa hoàn thành" },
                  }),
                  _c("el-option", {
                    attrs: { value: "completeYes", label: "Đã hoàn thành" },
                  }),
                  _c("el-option", {
                    attrs: { value: "payPart", label: "Đóng còn thiếu" },
                  }),
                  _c("el-option", {
                    attrs: { value: "payFull", label: "Đóng thiếu 100%" },
                  }),
                  _c("el-option", {
                    attrs: {
                      value: "orderInComplete",
                      label: "Còn tồn hóa đơn",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-input",
                {
                  staticClass: "input-common input-class",
                  staticStyle: { width: "250px" },
                  attrs: { placeholder: "Nhập tên học sinh", clearable: "" },
                  on: {
                    clear: function ($event) {
                      return _vm.searchOrderKidsByProperties()
                    },
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.searchOrderKidsByProperties()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.fullName,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "fullName", $$v)
                    },
                    expression: "dataSearch.fullName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: {
                      click: function ($event) {
                        return _vm.searchOrderKidsByProperties()
                      },
                    },
                    slot: "append",
                  }),
                ],
                1
              ),
              _c(
                "el-checkbox",
                {
                  on: {
                    change: function ($event) {
                      return _vm.searchOrderKidsByProperties()
                    },
                  },
                  model: {
                    value: _vm.dataSearch.walletStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataSearch, "walletStatus", $$v)
                    },
                    expression: "dataSearch.walletStatus",
                  },
                },
                [_vm._v("Còn dư ví")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { float: "right" } },
            [
              _vm.checkPermission(["fees_order_statistical"])
                ? _c(
                    "el-dropdown",
                    { on: { command: _vm.handleStatisticalCommandMethod } },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "button-bottom",
                          attrs: { type: "success", icon: "el-icon-s-data" },
                        },
                        [
                          _vm._v("\n            Thống kê\n            "),
                          _c("i", { staticClass: "el-icon-caret-bottom" }),
                        ]
                      ),
                      _c(
                        "el-dropdown-menu",
                        { attrs: { slot: "dropdown" }, slot: "dropdown" },
                        [
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "statisticalMiniCommand" } },
                            [_vm._v("Số liệu học phí")]
                          ),
                          _c(
                            "el-dropdown-item",
                            { attrs: { command: "statisticalAllCommand" } },
                            [_vm._v("Tổng hợp số liệu")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticStyle: { "border-radius": "0", "margin-left": "5px" },
                  attrs: { type: "success", icon: "el-icon-search" },
                  on: {
                    click: function ($event) {
                      return _vm.kidsDialogMethod()
                    },
                  },
                },
                [_vm._v("\n          Tìm kiếm\n        ")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "table-content row-data" },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loadingData,
                    expression: "loadingData",
                  },
                ],
                ref: "kidsPackageForKidsRef",
                attrs: {
                  data: _vm.responseFilterList,
                  "empty-text": _vm.textTable,
                  "element-loading-text": _vm.$tableLoading,
                  "element-loading-spinner": "el-icon-loading",
                  "element-loading-background": "rgba(255,255,255, 0)",
                  "cell-style": _vm.tableRowStyle,
                  "header-cell-style": _vm.tableHeaderColor,
                  "max-height": _vm.$tableMaxHeight,
                  border: "",
                },
                on: { "selection-change": _vm.handleSelectionChange },
              },
              [
                _c("el-table-column", {
                  attrs: { type: "selection", align: "center", width: "55" },
                }),
                _c("el-table-column", {
                  attrs: {
                    type: "index",
                    fixed: "",
                    label: "STT",
                    width: "50",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: { "min-width": "150", fixed: "", label: "Họ tên" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "el-button",
                            {
                              staticClass: "click-in-row",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.detialApprovedKidsMethod(scope.row)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " + _vm._s(scope.row.fullName)
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { label: "Ngày sinh", width: "120", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("formatDate")(scope.row.birthDay))
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { width: "125", align: "center", label: "Mã hóa đơn" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.orderKids != null
                            ? [
                                _c("span", [
                                  _vm._v(_vm._s(scope.row.orderKids.code)),
                                ]),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("el-table-column", {
                  attrs: { align: "center", width: "70", label: "Hiển thị" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.orderKids != null
                            ? [
                                _c("el-checkbox", {
                                  on: {
                                    change: function ($event) {
                                      return _vm.viewOrderMethod(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.orderKids.view,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row.orderKids, "view", $$v)
                                    },
                                    expression: "scope.row.orderKids.view",
                                  },
                                }),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("el-table-column", {
                  attrs: { align: "center", width: "70", label: "Khóa" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.orderKids != null
                            ? [
                                _c("el-checkbox", {
                                  attrs: {
                                    disabled: !_vm.checkPermission([
                                      "fees_order_locked",
                                    ]),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.lockedOrderMethod(scope.row)
                                    },
                                  },
                                  model: {
                                    value: scope.row.orderKids.locked,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        scope.row.orderKids,
                                        "locked",
                                        $$v
                                      )
                                    },
                                    expression: "scope.row.orderKids.locked",
                                  },
                                }),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    width: "120",
                    label: "Tồn hóa đơn cũ",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.inCompleteOrderNumber > 0
                            ? [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.orderKidsMonthNoMethod(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.inCompleteOrderNumber)
                                    ),
                                  ]
                                ),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("el-table-column", {
                  attrs: { align: "center", width: "125", label: "Trạng thái" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.orderKids != null
                            ? [
                                scope.row.orderKids.totalNumber == 0
                                  ? _c("span", [_vm._v("Chưa có khoản")])
                                  : scope.row.orderKids.totalNumber !=
                                    scope.row.orderKids.enoughNumber
                                  ? _c("span", [_vm._v("Chưa hoàn thành")])
                                  : _c("span", [_vm._v("Đã hoàn thành")]),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "center",
                    width: "150",
                    label: "Đủ-Thiếu-Chưa đóng",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.orderKids != null
                            ? [
                                _c(
                                  "span",
                                  { staticStyle: { color: "#606266" } },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.orderKids.enoughNumber)
                                    ),
                                  ]
                                ),
                                _vm._v("\n            -\n            "),
                                _c(
                                  "span",
                                  { staticStyle: { color: "orange" } },
                                  [
                                    _vm._v(
                                      _vm._s(scope.row.orderKids.partNumber)
                                    ),
                                  ]
                                ),
                                _vm._v("\n            -\n            "),
                                _c("span", { staticStyle: { color: "red" } }, [
                                  _vm._v(
                                    _vm._s(scope.row.orderKids.emptyNumber)
                                  ),
                                ]),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("el-table-column", {
                  attrs: { align: "right", width: "120", label: "Còn lại thu" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.orderKids != null
                            ? [
                                _c("span", { staticClass: "high-light" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.orderKids.totalMoneyRemainIn
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("el-table-column", {
                  attrs: { align: "right", width: "120", label: "Còn lại chi" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.orderKids != null
                            ? [
                                _c("span", { staticClass: "high-light" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.orderKids.totalMoneyRemainOut
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    align: "right",
                    width: "120",
                    label: "T.Thu - T.Chi",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.orderKids != null
                            ? [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.orderKids.moneyTotalIn -
                                          scope.row.orderKids.moneyTotalOut
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("el-table-column", {
                  attrs: { align: "right", width: "120", label: "Tổng thu" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.orderKids != null
                            ? [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.orderKids.moneyTotalIn
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("el-table-column", {
                  attrs: { align: "right", width: "120", label: "Đã thu" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.orderKids != null
                            ? [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.orderKids.moneyPaidIn
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("el-table-column", {
                  attrs: { align: "right", width: "120", label: "Thu thiếu" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.orderKids != null
                            ? [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.orderKids.moneyTotalIn -
                                          scope.row.orderKids.moneyPaidIn
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("el-table-column", {
                  attrs: { align: "right", width: "120", label: "Tổng chi" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.orderKids != null
                            ? [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.orderKids.moneyTotalOut
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("el-table-column", {
                  attrs: { align: "right", width: "120", label: "Đã chi" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.orderKids != null
                            ? [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.orderKids.moneyPaidOut
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("el-table-column", {
                  attrs: { align: "right", width: "120", label: "Chi thiếu" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return scope.row.orderKids != null
                            ? [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatCurrencyNew")(
                                        scope.row.orderKids.moneyTotalOut -
                                          scope.row.orderKids.moneyPaidOut
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            : undefined
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    label: "Tác vụ",
                    fixed: "right",
                    width: _vm.checkPermission(["fees_order_payment"])
                      ? 330
                      : 230,
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm.checkPermission(["fees_order_payment"])
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    disabled:
                                      scope.row.orderKids == null ||
                                      scope.row.orderKids.totalNumber == 0 ||
                                      scope.row.orderKids.locked,
                                    size: "mini",
                                    type: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.orderKidsCasherMethod(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("Thanh toán")]
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled:
                                  scope.row.orderKids == null ||
                                  scope.row.orderKids.totalNumber == 0,
                                type: "success",
                                size: "mini",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.orderKidsDetailMethod(scope.row)
                                },
                              },
                            },
                            [_vm._v("\n              Chi tiết")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled:
                                  scope.row.orderKids == null ||
                                  scope.row.orderKids.totalNumber == 0,
                                size: "mini",
                                type: "warning",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.orderKidsHistoryMethod(scope.row)
                                },
                              },
                            },
                            [_vm._v("\n              Lịch sử")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.orderKidsMonthMethod(scope.row)
                                },
                              },
                            },
                            [_c("i", { staticClass: "el-icon-circle-plus" })]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { float: "right", margin: "15px 0" } },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "button-bottom",
                    attrs: {
                      loading: _vm.loaddingButtonGenerate,
                      type: "success",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.gengerateOrderMethod()
                      },
                    },
                  },
                  [_vm._v("\n          Khởi tạo hóa đơn\n        ")]
                ),
                _c(
                  "el-dropdown",
                  { on: { command: _vm.handlePrintCommandMethod } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button-bottom",
                        attrs: { type: "success" },
                      },
                      [
                        _vm._v("\n            In hóa đơn\n            "),
                        _c("i", { staticClass: "el-icon-caret-bottom" }),
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "allPrintCommand-1" } },
                          [_vm._v("Tổng hợp 1 liên")]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "allPrintCommand-2" } },
                          [_vm._v("Tổng hợp 2 liên")]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "inPrintCommand-1" } },
                          [_vm._v("Thu 1 liên")]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "outPrintCommand-1" } },
                          [_vm._v("Chi 1 liên")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-dropdown",
                  { on: { command: _vm.handleCommandMethod } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "button-bottom",
                        attrs: {
                          type: "success",
                          loading: _vm.loaddingButtonAction,
                        },
                      },
                      [
                        _vm._v("\n            Tác vụ\n            "),
                        _c("i", { staticClass: "el-icon-caret-bottom" }),
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "viewCommand" } },
                          [_vm._v("Hiển thị")]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "unViewCommand" } },
                          [_vm._v("Bỏ hiển thị")]
                        ),
                        _vm.checkPermission(["fees_order_locked"])
                          ? _c(
                              "el-dropdown-item",
                              { attrs: { command: "lockedCommand" } },
                              [_vm._v("Khóa")]
                            )
                          : _vm._e(),
                        _vm.checkPermission(["fees_order_locked"])
                          ? _c(
                              "el-dropdown-item",
                              { attrs: { command: "unLockedCommand" } },
                              [_vm._v("Bỏ khóa")]
                            )
                          : _vm._e(),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "viewDetailAll" } },
                          [_vm._v("Xem tổng hợp")]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "exportExcelNowCommand" } },
                          [_vm._v("Xuất Excel hiện tại")]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "exportExcelCommand" } },
                          [_vm._v("Xuất Excel học phí")]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "sendNotifyFees" } },
                          [_vm._v("Báo học phí qua SMS")]
                        ),
                        _c(
                          "el-dropdown-item",
                          { attrs: { command: "sendNotifyFeesApp" } },
                          [_vm._v("Báo học phí qua App")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Thiết lập cỡ chữ file PDF",
            visible: _vm.dialogOptionsPdfVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogOptionsPdfVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 6 } }, [
                _c("div", [_c("span", [_vm._v("Chọn cỡ chữ: ")])]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "70px" },
                      attrs: { placeholder: "Chọn cỡ chữ", size: "smail" },
                      model: {
                        value: _vm.fontSizePdf,
                        callback: function ($$v) {
                          _vm.fontSizePdf = $$v
                        },
                        expression: "fontSizePdf",
                      },
                    },
                    _vm._l(_vm.listFontSize, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogOptionsPdfVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loadingButtonPrint, type: "primary" },
                  on: { click: _vm.handleConfirmDownloadPdf },
                },
                [_vm._v("Xác nhận")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("OrderKidsCasher", {
        ref: "OrderKidsCasher",
        attrs: { dialogVisible: _vm.showOrderKidsCasherDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeOrderKidsCasherDialog()
          },
        },
      }),
      _c("OrderKidsHistoryDialog", {
        ref: "OrderKidsHistoryDialog",
        attrs: { dialogVisible: _vm.showOrderKidsHistoryDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeOrderKidsHistoryDialog()
          },
        },
      }),
      _c("OrderKidsMonthDialog", {
        ref: "OrderKidsMonthDialog",
        attrs: { dialogVisible: _vm.showOrderKidsMonthDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeOrderKidsMonthDialog()
          },
        },
      }),
      _c("OrderKidsDetailDialog", {
        ref: "OrderKidsDetailDialog",
        attrs: { dialogVisible: _vm.showOrderKidsDetailDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeOrderKidsDetailDialog()
          },
        },
      }),
      _c("KidsInfoData", {
        attrs: { dialogVisible: _vm.showKidsDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeKidsDialog()
          },
        },
      }),
      _c("StatisticalCommonDialog", {
        ref: "StatisticalCommonDialog",
        attrs: { dialogVisible: _vm.showStatisticalCommonDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeStatisticalCommonDialog()
          },
        },
      }),
      _c("StatisticalCommonMiniDialog", {
        ref: "StatisticalCommonMiniDialog",
        attrs: { dialogVisible: _vm.showStatisticalCommonMiniDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeStatisticalCommonMiniDialog()
          },
        },
      }),
      _c("ViewAllDataClassDialog", {
        ref: "ViewAllDataClassDialog",
        attrs: {
          dataList: _vm.responseFilterList,
          classNameData: _vm.classNameData,
          dialogVisible: _vm.showDialogViewAll,
        },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeViewAllDialog()
          },
        },
      }),
      _c("OrderPrintManyKidDialog", {
        ref: "OrderPrintManyKidDialog",
        attrs: { dialogVisible: _vm.showPrintOrderDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closePrintOrderDialog()
          },
        },
      }),
      _c("PackageKidsApprovedKidDialog", {
        ref: "PackageKidsApprovedKidDialog",
        attrs: { dialogVisible: _vm.showDetialApprovedDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closeDetialApprovedDialog()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }