var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Lịch sửa hóa đơn: " + _vm.orderCode,
            visible: _vm.dialogVisible,
            width: "1200px",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                "empty-text": _vm.textTable,
                "highlight-current-row": "",
                data: _vm.responseList,
                "cell-style": _vm.tableRowStyle,
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": _vm.$tableMaxHeight,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  fixed: "",
                  label: "STT",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "125",
                  fixed: "",
                  align: "center",
                  label: "Ngày thanh toán",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("formatDate")(scope.row.date))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Người thực hiện",
                  width: "150",
                  prop: "createdBy",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Người thanh toán",
                  width: "150",
                  prop: "name",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "Loại", align: "center", width: "70" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.category == "in"
                          ? _c("span", [_vm._v("Thu")])
                          : _vm._e(),
                        scope.row.category == "out"
                          ? _c("span", [_vm._v("Chi")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Tiền mặt nhập", align: "right", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrency")(scope.row.moneyInput)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "Tiền ví nhập", align: "right", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrency")(scope.row.moneyWallet)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Tiền thanh toán",
                  align: "right",
                  width: "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatCurrency")(scope.row.moneyPayment)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Mô tả",
                  prop: "description",
                  "min-width": "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Tác vụ",
                  fixed: "right",
                  width: "115",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "success", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.orderKidsHistoryDetailMethod(
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.view")))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("OrderKidsHistoryDetailDialog", {
        ref: "OrderKidsHistoryDetailDialog",
        attrs: { dialogVisible: _vm.showOrderHistoryDetailDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closesOrderHistoryDetailDialog()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }