<template>
  <div style="visibility: hidden">
    <el-dialog
      class="andanh"
      title="In phiếu thu phí"
      :visible.sync="dialogVisible"
      width="550px"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      top="3vh"
    >
      <span ref="documentPrint">
        <br />
        <div>
          <div>
            <el-row :gutter="10">
              <el-col style="text-align: right; width: 18%">
                <img
                  :src="responseData.avatar"
                  alt="Girl in a jacket"
                  width="70"
                  height="70"
                />
              </el-col>
              <el-col style="width: 82%">
                <div style="text-align: center;  width: 360px">
                  <div style="font-weight: bold">
                    {{ responseData.schoolName }}
                  </div>
                  <div style="margin-bottom: 4px">
                    {{ responseData.schoolAddress }}
                  </div>
                  <div style="font-weight: bold">
                    PHIẾU THU PHÍ THÁNG
                    {{ this.moment(this.date).format("MM/YYYY") }}
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div>
            <div style="float: left">
              <div style="font-weight: bold">Họ tên: {{ responseData.fullName }}</div>
              <div style="font-weight: bold">Lớp: {{ responseData.className }}</div>
              <div>SĐT: {{ responseData.phone }}</div>
              <div
                style="font-style: italic; margin-bottom: 3px"
              >
                Ngày {{ this.moment().format("DD") }} tháng
                {{ this.moment().format("MM") }} năm
                {{ this.moment().format("YYYY") }}
              </div>
            </div>
            <div style="float: right">
              <div style="text-align: right">Mã: ..........</div>
              <div style="text-align: right">Quyển số: ..........</div>
              <div style="text-align: right">Số: ..........</div>
              <div style="margin-bottom: 3px; text-align: right">
                Đơn vị: VNĐ
              </div>
            </div>
          </div>
          <br />
          <br />
          <el-table
            class="incainay"
            ref="multipleTable"
            :empty-text="textTable"
            :data="responseData.dataList"
            :cell-style="tableRowStyle"
            :header-cell-style="tableHeaderColor"
            :span-method="arraySpanMethod"
            border
          >
            <el-table-column
              type="index"
              label="STT"
              width="50"
              align="center"
            ></el-table-column>
            <el-table-column label="Tên loại phí" width="180">
              <template v-slot:default="scope">
                <div
                  :style="
                    scope.row.name === 'Tổng cộng' ||
                    scope.row.name === 'Số dư trong ví' ||
                    scope.row.name === 'Số tiền đã trả' ||
                    scope.row.name === 'Số tiền còn thiếu'
                      ? 'text-align: right'
                      : ''
                  "
                >
                  {{ scope.row.name }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="number"
              label="SL"
              align="center"
              width="50"
            ></el-table-column>
            <el-table-column label="Đơn giá" align="right" width="80">
              <template v-slot:default="scope">
                <span>{{ scope.row.price | formatNumber }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Giảm giá" align="right" width="80">
              <template v-slot:default="scope">
                <span>{{scope.row.discountPrice | formatNumber }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Thành tiền" align="right" width="80">
              <template v-slot:default="scope">
                <span>{{ scope.row.money | formatNumber }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div
            style="
              margin-top: 4px;
              font-style: italic;
              margin-bottom: 3px;
              color: red;
            "
            class=""
          >
            *{{ responseData.bankInfo }}
          </div>
          <div>
            Ngân hàng: {{ responseData.bankNameBank }}
          </div>
          <div>
            Số tài khoản: {{ responseData.accountNumberBank }}
          </div>
          <div>
            Chủ tài khoản: {{ responseData.fullNameBank }}
          </div>
            <el-row   class="footer_field">
              <el-col :span="12"><b>Người nộp tiền</b></el-col>
              <el-col :span="12"><b>Người lập phiếu</b></el-col>
              <el-col  :offset="12" :span="12" style="padding-top: 40px"><b>{{ responseData.userCreate}}</b></el-col>
            </el-row>
        </div>
        <div style="margin-top: 80px">{{ description }}</div>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="success"
          size="medium"
          @click="exportToPDFMethod()"
        >
          <i class="el-icon-printer" />
          <span>In hóa đơn </span>
        </el-button>
        <el-button type="danger" size="medium" @click="closeDialog()">
          <i class="el-icon-circle-close" />
          <span>{{ $t("button.close") }}</span>
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import FnFeesService from "@/services/FinanceKids/FnFeesService";
import html2pdf from "../../../../node_modules/html2pdf.js/dist/html2pdf";
import moment from "moment";

export default {
  props: {
    dialogVisible: null,
  },
  data() {
    return {
      fontSizeBase: 8,
      textTable: this.$tableEmpty,
      responseList: [],
      responseData: {},
      loadingButton: false,
      showOrderKidsAllDialog: false,
      date: "",
      description: "",
      currentTime: '',
      day: '',
      month: '',
      year: '',
    };
  },
  watch: {
    fontSizeBase(newValue) {
      document.documentElement.style.setProperty('--custom-font-size-note-pdf', (newValue - 1.3) + "px");
    },
  },
  created() {
    // Lấy ngày hiện tại
    let now = new Date();
    // Lấy giờ-phút hiện tại
    let hours = now.getHours();
    let minutes = now.getMinutes();
    this.currentTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;

// Lấy ngày-tháng-năm hiện tại
    let dayNow = now.getDate();
    this.day = `${dayNow < 10 ? '0' + dayNow : dayNow}`
    let monthNow = now.getMonth() + 1; // Tháng bắt đầu từ 0
    this.month = `${monthNow < 3 ? '0' + monthNow : monthNow}`
    this.year = now.getFullYear();
  },
  methods: {
    moment,
    tableHeaderColor() {
      return "background-color: #b3b3b3;color: #fff;font-weight: bold;";
    },
    tableRowStyle({ row }) {
      if (
        row.name === "Tổng cộng" ||
        row.name === "Số dư trong ví" ||
        row.name === "Số tiền đã trả" ||
        row.name === "Số tiền còn thiếu" ||
        row.identify === "moneyString"
      ) {
        if (row.name === "Tổng cộng") {
          return "font-weight: bold; background: #b3b3b3";
        } else if (row.identify === "moneyString") {
          return "font-style: italic";
        } else {
          return "font-weight: bold";
        }
      }
    },
    closeDialog() {
      this.$emit("dialog-close");
      setTimeout(() => {}, 300);
    },
    arraySpanMethod({ row, columnIndex }) {
      if (
        row.name === "Tổng cộng"||
        row.name === "Số dư trong ví" ||
        row.name === "Số tiền đã trả" ||
        row.name === "Số tiền còn thiếu"
      ) {
        if (columnIndex === 1) {
          return [1, 4];
        } else if (columnIndex === 5) {
          return [1, 3];
        } else {
          return [0, 0];
        }
      } else if (row.identify === "moneyString") {
        if (columnIndex === 1) {
          return [1, 6];
        } else {
          return [0, 0];
        }
      }
    },
    exportToPDFMethod(size) {
      let element = this.$refs.documentPrint;
      element.style.fontSize = `${size}px`;

      let opt = {
        margin: [0, 0.2],
        filename:
          "PHIEU_THU_PHI_THANG_" +
          this.moment(this.date).format("MM_YYYY") +
          ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2, y: 0, scrollY: 0, removeContainer: false },
        jsPDF: { unit: "in", format: "A5", orientation: "portrait" },
        pagebreak: { mode: "css" },
      };
      html2pdf()
          .set(opt)
          .from(element)
          .save();
      this.$emit("dialog-close");
    },
    async convertMoneyToString() {
      let rowData = this.responseData.dataList.filter(
        (x) => x.name === "Số tiền còn thiếu"
      )[0];
      await import("@/services/ConvertMoneyToString").then((x) => {
        let moneyString = x.ConvertMoneyToString(rowData.money);
        let rowInput = {
          identify: "moneyString",
          name: moneyString,
        };
        this.responseData.dataList.push(rowInput);
      });
    },
    async printMethod(idKid, date, category, idList, description) {
      this.date = date;
      this.description = description;
      await FnFeesService.OrderPrintIn(idKid, category, idList)
        .then((resp) => {
          this.responseData = resp.data.data;
        })
        .catch((err) => {
          this.$message({
            message: err.response.data.message,
            type: "error",
          });
        });
    },
    async getDataInitialPrint(idKid, date, category, idList, description, size) {
      await this.printMethod(idKid, date, category, idList, description);
      await this.convertMoneyToString();
      this.exportToPDFMethod(size);
    },
  },
};
</script>

<style lang="scss" scoped>
:root {
  --custom-font-size-pdf: 12px;
  --custom-font-size-note-pdf: 8px;
}

/deep/.el-table {
  font-size: var(--custom-font-size-pdf);
}

.size-note-pdf {
  font-size: var(--custom-font-size-note-pdf);
}

/deep/.el-dialog__title {
  font-size: 25px;
  color: #606266;
}
/deep/.el-form-item__label {
  font-family: Arial, Helvetica, sans-serif;
}
/deep/.el-table td > .cell {
  word-break: break-word;
  font-family: arial,serif;
  color: black;
  padding: 0 4px !important;
}
/deep/.el-table th > .cell {
  word-break: break-word;
  color: black;
}
/deep/.el-dialog__body {
  padding: 0;
}
/deep/.el-table td {
  padding: 0 0 !important;
  border-bottom: 1px solid #989ba0;
  border-right: 1px solid #989ba0;
}
/deep/.el-table th {
  padding: 0 0 !important;
  border-right: 1px solid #909399;
}
.el-table {
  border: 1px solid #909399;
  border-radius: 0;
}
.input-common {
  margin-right: 5px;
}
.footer_field {
  height: 18vh;
  text-align: center;
}
</style>
