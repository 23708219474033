var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Danh sách hóa đơn học sinh: " + _vm.fullName,
            visible: _vm.dialogVisible,
            width: "90%",
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "margin-bottom": "15px",
                position: "relative",
                "z-index": "1",
              },
            },
            [
              _c("el-date-picker", {
                staticClass: "input-common",
                staticStyle: { width: "130px" },
                attrs: {
                  type: "year",
                  "value-format": "yyyy-MM-dd",
                  format: "yyyy",
                  placeholder: "Chọn năm",
                },
                on: {
                  change: function ($event) {
                    return _vm.searchByProperties()
                  },
                },
                model: {
                  value: _vm.dataSearch.year,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataSearch, "year", $$v)
                  },
                  expression: "dataSearch.year",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    background: "#67c23a",
                    "border-top-left-radius": "5px",
                    "border-bottom-left-radius": "5px",
                  },
                  attrs: { span: 4 },
                },
                [
                  _vm._v("\n        Tổng thu:\n        "),
                  _c("span", [
                    _vm.responseData.moneyTotalIn == 0
                      ? _c("span", [_vm._v("0")])
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.responseData.moneyTotalIn
                                )
                              )
                          ),
                        ]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticStyle: { background: "#f56c6c" }, attrs: { span: 4 } },
                [
                  _vm._v("\n        Đã thu:\n        "),
                  _c("span", [
                    _vm.responseData.moneyPaidIn == 0
                      ? _c("span", [_vm._v("0")])
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.responseData.moneyPaidIn
                                )
                              )
                          ),
                        ]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticStyle: { background: "#e6a23c" }, attrs: { span: 4 } },
                [
                  _vm._v("\n        Thu thiếu:\n        "),
                  _c("span", [
                    _vm.responseData.moneyTotalIn -
                      _vm.responseData.moneyPaidIn ==
                    0
                      ? _c("span", [_vm._v("0")])
                      : _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.responseData.moneyTotalIn -
                                    _vm.responseData.moneyPaidIn
                                )
                              )
                          ),
                        ]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticStyle: { background: "#67c23a" }, attrs: { span: 4 } },
                [
                  _vm._v("\n        Tổng chi:\n        "),
                  _c("span", [
                    _vm.responseData.moneyTotalOut == 0
                      ? _c("span", [_vm._v("0")])
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.responseData.moneyTotalOut
                                )
                              )
                          ),
                        ]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                { staticStyle: { background: "#f56c6c" }, attrs: { span: 4 } },
                [
                  _vm._v("\n        Đã chi:\n        "),
                  _c("span", [
                    _vm.responseData.moneyPaidOut == 0
                      ? _c("span", [_vm._v("0")])
                      : _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.responseData.moneyPaidOut
                                )
                              )
                          ),
                        ]),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    background: "#e6a23c",
                    "border-top-right-radius": "5px",
                    "border-bottom-right-radius": "5px",
                  },
                  attrs: { span: 4 },
                },
                [
                  _vm._v("\n        Chi thiếu:\n        "),
                  _c("span", [
                    _vm.responseData.moneyTotalOut -
                      _vm.responseData.moneyPaidOut ==
                    0
                      ? _c("span", [_vm._v("0")])
                      : _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm._f("formatCurrency")(
                                  _vm.responseData.moneyTotalOut -
                                    _vm.responseData.moneyPaidOut
                                )
                              )
                          ),
                        ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              ref: "multipleTable",
              attrs: {
                "empty-text": _vm.textTable,
                "highlight-current-row": "",
                data: _vm.responseData.dataList,
                "cell-style": _vm.tableRowStyle,
                "header-cell-style": _vm.tableHeaderColor,
                "max-height": _vm.$tableMaxHeightSmall,
                border: "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  width: "60",
                  fixed: "",
                  prop: "month",
                  align: "center",
                  label: "Tháng",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "125",
                  fixed: "",
                  prop: "code",
                  align: "center",
                  label: "Mã hóa đơn",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", width: "70", label: "Hiện thị" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.view
                          ? _c("span", [_vm._v("Có")])
                          : _c("span", [_vm._v("Không")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", width: "70", label: "Khóa" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.locked
                          ? _c("span", [_vm._v("Có")])
                          : _c("span", [_vm._v("Không")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  "min-width": "120",
                  sortable: "",
                  label: "Trạng thái",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.totalNumber == 0
                          ? _c("span", [_vm._v("Chưa có khoản")])
                          : scope.row.totalNumber != scope.row.enoughNumber
                          ? _c("span", [_vm._v("Chưa hoàn thành")])
                          : _c("span", [_vm._v("Đã hoàn thành")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "150",
                  label: "Đủ-Thiếu-Chưa đóng",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _c("span", { staticStyle: { color: "#606266" } }, [
                            _vm._v(_vm._s(scope.row.enoughNumber)),
                          ]),
                          _vm._v("\n            -\n            "),
                          _c("span", { staticStyle: { color: "orange" } }, [
                            _vm._v(_vm._s(scope.row.partNumber)),
                          ]),
                          _vm._v(" -\n            "),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v(_vm._s(scope.row.emptyNumber)),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "right", width: "120", label: "Tổng thu" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.moneyTotalIn == 0
                          ? _c("span", [_vm._v("0")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    scope.row.moneyTotalIn
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "right", width: "120", label: "Đã thu" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.moneyPaidIn == 0
                          ? _c("span", [_vm._v("0")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    scope.row.moneyPaidIn
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "right", width: "120", label: "Thu thiếu" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.moneyTotalIn - scope.row.moneyPaidIn == 0
                          ? _c("span", [_vm._v("0")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    scope.row.moneyTotalIn -
                                      scope.row.moneyPaidIn
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "right", width: "120", label: "Tổng chi" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.moneyTotalOut == 0
                          ? _c("span", [_vm._v("0")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    scope.row.moneyTotalOut
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "right", width: "120", label: "Đã chi" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.moneyPaidOut == 0
                          ? _c("span", [_vm._v("0")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    scope.row.moneyPaidOut
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "right", width: "120", label: "Chi thiếu" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.moneyTotalOut - scope.row.moneyPaidOut == 0
                          ? _c("span", [_vm._v("0")])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatCurrency")(
                                    scope.row.moneyTotalOut -
                                      scope.row.moneyPaidOut
                                  )
                                )
                              ),
                            ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "Tác vụ",
                  fixed: "right",
                  width: "200",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.checkPermission(["fees_order_locked"])
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  disabled:
                                    scope.row.locked ||
                                    scope.row.totalNumber == 0,
                                  size: "mini",
                                  type: "primary",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.orderKidsCasherMethod(scope.row)
                                  },
                                },
                              },
                              [_vm._v("Thanh toán")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: scope.row.totalNumber == 0,
                              size: "mini",
                              type: "success",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.orderKidsDetailMethod(scope.row)
                              },
                            },
                          },
                          [_vm._v("Chi tiết")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "medium" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-circle-close" }),
                  _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("OrderKidsCasher", {
        ref: "OrderKidsCasher",
        attrs: { dialogVisible: _vm.showOrderKidsCasherDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closesOrderKidsCasherDialog()
          },
        },
      }),
      _c("OrderKidsDetailDialog", {
        ref: "OrderKidsDetailDialog",
        attrs: { dialogVisible: _vm.showOrderKidsDetailDialog },
        on: {
          "dialog-close": function ($event) {
            return _vm.closesOrderKidsDetailDialog()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }